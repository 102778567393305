import React from "react";
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import styled from "styled-components";
import { Image } from "../components/core/image";
import { Form } from "../components/forms";
import { IconDownload } from "../components/icons";
import { Layout } from "../components/layout";
import MdxContent from "../components/mdx-content";
import { Box } from "../components/rebrand/core/box";
import { Button } from "../components/rebrand/core/Button/Button";
import { Grid } from "../components/rebrand/core/Grid/Grid";
import { Paper } from "../components/rebrand/core/Paper/Paper";
import { Typography } from "../components/rebrand/core/Typography/Typography";
import { Section } from "../components/rebrand/sections/Section/Section";
import { YoutubeEmbed } from "../components/sections/youtube-embed/YoutubeEmbed";
import { SEO } from "../components/seo";
import { resourceShortcodes } from "../components/shortcodes/resource";

const StyledLayout = styled(Layout)`
  background: linear-gradient(180deg, rgb(255, 255, 255) 40%, rgb(243, 244, 252) 82.21%);
`;

interface MDXResourceTemplateProps extends PageProps {
  data: Queries.WhitePapersPageQuery;
}

export const pageQuery = graphql`
  query WhitePapers {
    site {
      siteMetadata {
        pages {
          whitePapers {
            title
            description
            keywords
          }
        }
      }
    }
  }
`;

export default function Whitepaper(props: MDXResourceTemplateProps) {

  const {
    location: { pathname },
    data:  { site }
  } = props;


  const { title, description, keywords } = site?.siteMetadata?.pages?.whitePapers as Queries.SiteSiteMetadataPagesWhitePapers;
  const seo = {
    title: title as string,
    description: description as string,
    // keywords: keywords as [],
    pathname,
  };

  const cta = {
    caption : 'Una publicación de tecnología bisemanal',
    title : 'Descarga hoy nuestro WhitePaper', 
    subtitle : 'Subtitulo',
    button : {
        href : '#',
        text : 'Descargar ahora'
    }
  };

  let FeaturedContent = (
      <>
        <Box
          gridColumn={["3 / span 8", "4 / span 6", "2 / span 4"]}
          marginLeftSpacing="-11%"
          gridRow={1}
          paddingYSpacing={[6, 6, 12.5]}
        >
          {/* <Image data={featuredImage} alt="Resource Image" /> */}
        </Box>
        <Box
          gridColumn={["1 / span 12", "1 / span 12", "7 / span 5"]}
          gridRow={[2, 2, "1 / span 2"]}
          paddingTopSpacing={[0, 0, 10]}
          paddingBottomSpacing={[0, 0, 3]}
        >
          <Paper
            position={["static", "static", "sticky"]}
            top="7rem"
            backgroundColor={["transparent", "transparent", "background.base"]}
          >
            {cta.caption && (
              <Typography variant="label2" color="text.red" marginBottomSpacing={1.5}>
                {cta.caption}
              </Typography>
            )}
            <Typography variant="h3" marginBottomSpacing={1.5}>
              {cta.title}
            </Typography>
            <Typography variant="body2" color="text.muted" marginBottomSpacing={2}>
              {cta.subtitle}
            </Typography>
            <Form type="lead" gridGap="14px" />
          </Paper>
        </Box>
      </>
    );


  return (
    <StyledLayout>
      <SEO {...seo} />
      <Section
        paddingTopSpacing={[10, 10]}
        paddingBottomSpacing={[0, 0]}
        backgroundColor="transparent"
        overflow="visible"
      >
        <Grid gridTemplateColumns="repeat(12, 1fr)">
          <Box gridColumn={["1 / span 12"]} gridRow={1} backgroundColor="background.faint" borderRadius={4} />
          {FeaturedContent}
            <Box
              gridColumn={["1 / span 12", "1 / span 12", `1 / span ${6}`]}
              paddingLeftSpacing={[0, 0, 6]}
            >
              <MdxContent shortcodes={resourceShortcodes}>
                Texto
              </MdxContent>
            </Box>
        </Grid>
      </Section>
    </StyledLayout>
  );
}
